import axiosIns from '@/libs/axios.js';

class UserService {
    delete(id)
    {
        return axiosIns.post(`/user/destroy/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    find(id)
    {
        return axiosIns.get(`/user/find/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    unenrollStudent(id)
    {
        var data = {
            id: id
        }
        return axiosIns.post(`unenroll/student`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        }) 
    }
}

export default new UserService();