import axiosIns from '@/libs/axios.js';

class TeacherSerevice {
    listing(){
        return axiosIns.get('/teacher/listing').then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    edit(id, data)
    {
        return axiosIns.post(`/update-teacher-profile/${id}`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    store(data)
    {
        return axiosIns.post(`teacher/store`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new TeacherSerevice();